<template>
  <div id="myPage-EditProfileLayout">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "CastMyPage-EditProfileLayout"
};
</script>

<style scoped></style>
